
import { defineComponent } from "@vue/runtime-core";
import AppPage from "@/components/AppPage.vue";
import TextInput from "@/components/TextInput.vue";
import SubsetInput from "@/components/SubsetInput.vue";
import DataTable from "@/components/DataTable.vue";
import Daterange from "@/components/Daterange.vue";
import Button from "@/components/Button.vue";
import { Option, Pagination, Sorting } from "@/use/types";
import Tooltip from "@/components/Tooltip.vue";
import DisactivateAffiliate from "./DisactivateAffiliate.vue";
import ActivateAffiliatePopup from "./ActivateAffiliatePopup.vue";

import moment, { Moment } from "moment-timezone";
import axios from "axios";
import xss, { IWhiteList } from "xss";

interface IDataModel {
  loading: boolean;
  filter: IQuery;
  rows: any[];
  totalRows: number;
  columns: Column[];
  definition: any;
  options: {
    affiliates: Option[];
  };
  editingColumnId: number | null;
  editingText: string;
  errors: { [key: string]: string | null } | null;
  isDisactivatePopupShowed: boolean;
  disactivateAffiliateId: number | null;
  isActivatePopupShowed: boolean;
  activateAffiliateId: number | null;
}

interface IQuery {
  period: Moment[];
  search: string;
  pagination: null | Pagination;
  sorting: Sorting;
  affiliate_ids: number[];
  is_active: boolean;
}

interface Column {
  id: number | string;
  name: string;
  selected: boolean;
  freezed?: boolean;
}

export function getDefaultQuery(): IQuery {
  return {
    period: [],
    search: "",
    sorting: { column: "created_at", direction: "desc" },
    pagination: { limit: 10, offset: 0 },
    affiliate_ids: [],
    is_active: true,
  };
}

const dataColumns = [
  {
    id: "created_at",
    name: "Дата регистрации",
    selected: true,
    sortable: true,
    freezed: true,
  },
  {
    id: "id",
    name: "ID Веба",
    selected: true,
    sortable: true,
    freezed: true,
  },
  {
    id: "name",
    name: "Имя",
    selected: true,
    sortable: false,
  },
  {
    id: "email",
    name: "email",
    selected: true,
    sortable: true,
    type: "text",
    searchable: true,
  },
  {
    id: "telegram",
    name: "telegram",
    selected: true,
    sortable: true,
    type: "text",
  },
  {
    id: "phone",
    name: "Номер телефона",
    selected: true,
    sortable: false,
  },
  {
    id: "comment",
    name: "Комментарий",
    selected: true,
    sortable: false,
  },
  {
    id: "buttons",
    name: "",
    selected: true,
    freezed: true,
    sortable: false,
  },
];

export default defineComponent({
  components: {
    AppPage,
    TextInput,
    SubsetInput,
    DataTable,
    Daterange,
    Button,
    Tooltip,
    DisactivateAffiliate,
    ActivateAffiliatePopup,
  },
  data(): IDataModel {
    return {
      loading: false,
      filter: getDefaultQuery(),
      rows: [],
      totalRows: 0,
      columns: dataColumns,
      options: {
        affiliates: [],
      },
      definition: {
        shortcut: null,
        timezone: "Europe/Kiev",
      },
      editingColumnId: null,
      editingText: "",
      errors: null,
      isDisactivatePopupShowed: false,
      disactivateAffiliateId: null,
      isActivatePopupShowed: false,
      activateAffiliateId: null,
    };
  },
  async created() {
    this.load();
    this.getAffiliateIds();
    await this.getPeriodLimits();
  },
  mounted: function () {
    this.$watch("filter.pagination", this.load);
    this.$watch("filter.sorting", this.load);
    this.$watch("filter.is_active", this.load);
  },
  methods: {
    async filterOptions(collection: string, query = "", field?: string) {
      const { data } = await axios.get(
        "/api/" + (field || collection) + "/lookup",
        {
          params: { q: JSON.stringify({ query }) },
        }
      );
      const key: "affiliates" = collection as any;
      this.options[key] = data;
    },
    async getPeriodLimits() {
      const { data } = await axios("/api/get-period-limits/affiliates");
      this.filter.period = [data.min, moment().endOf("day")];
    },
    async setPeriod(value: any) {
      if (!value.length) {
        await this.getPeriodLimits();
        return;
      }
      this.filter.period = value;
    },
    highlight(word: string): any {
      const whiteList: IWhiteList = {};

      let sanitized = xss(word, {
        whiteList,
      });

      let query = this.filter.search;
      let check = new RegExp(query, "ig");

      if (sanitized !== null) {
        return sanitized.replace(check, (matchedText) => {
          return "<strong>" + matchedText + "</strong>";
        });
      }
    },
    toDisactive() {
      this.filter.is_active = false;
    },
    toActive() {
      this.filter.is_active = true;
    },
    async close() {
      await this.getPeriodLimits();
      this.isDisactivatePopupShowed = false;
      this.isActivatePopupShowed = false;
    },
    onEditorUpdate(id: number, value: string) {
      this.editingText = value;
      this.editingColumnId = id;
    },
    cancelEditing() {
      this.editingColumnId = null;
      this.errors = null;
    },
    async saveEditing(id: number) {
      try {
        await axios.post("/api/affiliates/" + id, {
          comment: this.editingText,
        });

        this.editingColumnId = null;
        this.editingText = "";
        this.load();
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        }
      }
    },
    formatDate(d: string): string {
      return moment(d).format("DD.MM.YYYY");
    },
    async clear() {
      this.filter = getDefaultQuery();
      await this.getPeriodLimits();
      this.load();
    },
    updateAffiliatesAndLoad(e: any) {
      this.filter.affiliate_ids = e;
    },
    disactiveAffiliate(id: number) {
      this.isDisactivatePopupShowed = true;
      this.disactivateAffiliateId = id;
    },
    activateAffiliate(id: number) {
      this.isActivatePopupShowed = true;
      this.activateAffiliateId = id;
    },
    async getAffiliateIds() {
      const { data } = await axios.get("/api/affiliates/lookup");
      this.options.affiliates = data;
    },
    async load() {
      this.loading = true;
      this.rows = [];
      this.totalRows = 0;

      try {
        const { data } = await axios.get("/api/affiliates", {
          params: { q: JSON.stringify(this.filter) },
        });
        this.rows = data.rows;
        this.totalRows = data.total;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },
  },
});
