<template>
  <div class="tooltip">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  position: absolute;
  font-size: 12px;
  line-height: 16px;
  padding: 10px;
  box-shadow: 0 5px 7px rgba(133, 133, 175, 0.5);
  border-radius: 4px;
  background: #fff;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-75%);
  color: #00004f;
  font-style: italic;
  width: max-content;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}
</style>
