<template>
  <Popup @close="close" className="_center">
    <img src="img/attention-icon.svg" alt="" />
    <h2>
      Вы уверены, что хотите деактивировать аккаунт веба {{ affiliateId }}
    </h2>
    <div class="delete__actions">
      <Button
        text="Да"
        className="btn-primary"
        class="_filled"
        @click="disactiveAffiliate"
      />
      <Button text="Отмена" class="_outlined" @click="close" />
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/Popup";
import Button from "@/components/Button";
import axios from "axios";

export default {
  components: {
    Popup,
    Button,
  },
  props: {
    affiliateId: Number,
  },
  emits: ["close", "disactivate"],
  methods: {
    close() {
      this.$emit("close");
    },
    async disactiveAffiliate() {
      try {
        await axios.post(
          "/api/affiliates/" + this.affiliateId + "/disactivate"
        );
        this.$emit("disactivate");
        this.$emit("close");
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  text-align: center;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
}

img {
  margin: 0 auto 10px;
}

.delete__actions {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 50px;

  .btn {
    flex: 0 0 175px;
  }
}
</style>
